<template>
  <template v-if="isMobile">
    <div v-bind="$attrs" class="relative" @click="show = true">
      <slot :checked-label="checkedLabel">
      <!-- 默认内容 -->
      </slot>
    </div>
    <MobileSearchFilter v-model="show" :is-detail="isDetail" :page-type="pageType" />
  </template>
  <template v-else>
    <div v-bind="$attrs" class="relative" @click="show = true">
      <slot :checked-label="checkedLabel">
      </slot>
    </div>
    <DesktopSearchFilter v-model="show" :is-detail="isDetail" :page-type="pageType" />
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { E_RANK_TYPE } from '~/enums'

const props = withDefaults(defineProps<{
  onlyRead?: boolean
  isDetail?: boolean
  attrName?: string
  pageType: E_RANK_TYPE
}>(), {
  onlyRead: false,
  isDetail: false,
  attrName: '',
  pageType: E_RANK_TYPE.ATTRACTIONS,
})

const show = ref(false)
const isMobile = useMobileByScreen()
const { t } = useI18n()

const commonStore = useCommonStore()
const filterConditionsStore = useFilterConditionsStore()
const { filters } = useExperienceList()
const { filterParams } = useExperienceDetail()

const tagOptions = computed(() => commonStore.commonData.filterOpitonsInfo?.filters?.map(option => option.options!).flat() || [])

const checkedLabel = computed(() => {
  let filterIdList: string[] = []
  if (props.pageType === E_RANK_TYPE.EXPERIENCE) {
    filterIdList = (props.isDetail ? filterParams.value.freeCancellation : filters.value.freeCancellation)
      ? [t('key.experience.filter.free.cancellation')]
      : []
    if (props.isDetail ? filterParams.value.instantConfirmation : filters.value.instantConfirmation) {
      filterIdList.push(t('key.experience.filter.instant.confirmation'))
    }
    return filterIdList.join(',')
  }
  else {
    filterIdList = props.isDetail
      ? filterConditionsStore.filterConditions.detailFilterIdList
      : filterConditionsStore.filterConditions.filterIdList
  }
  return filterIdList
    .map((item) => {
      return tagOptions.value.find(tag => `${tag.category}_${tag.optionCode}` === item.toString())?.optionName
    })
    .filter(Boolean)
    .join(', ')
})
</script>
