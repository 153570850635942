<script lang="ts" setup>
import { E_RANK_TYPE } from '~/enums'

defineProps<{
  pageType: E_RANK_TYPE
  isDetail?: boolean
}>()
const showPopup = defineModel({ default: false })
const { t } = useI18n()
const filterRef = useTemplateRef('filter-ref')

function onConfirm() {
  showPopup.value = false
  filterRef.value?.onConfirm()
}
</script>

<template>
  <van-popup
    v-model:show="showPopup"
    position="bottom"
    class="az-search__popup"
    close-icon-position="top-left"
    closeable
    safe-area-inset-bottom
    close-on-popstate
  >
    <div class="h-full flex flex-col">
      <div class="py-2 pl-12" text="lg" font="700" border-b="1px #E4E4E4 solid">
        {{ t('key.experience.add.filters') }}
      </div>
      <div class="flex-1 overflow-y-auto">
        <div mb-9>
          <BusinessBFilter v-if="pageType === E_RANK_TYPE.ATTRACTIONS && showPopup" ref="filter-ref" :is-detail="isDetail" />
          <BusinessBExerienceFilter v-if="pageType === E_RANK_TYPE.EXPERIENCE && showPopup" ref="filter-ref" />
        </div>
      </div>
      <div class="px-4 py-4">
        <van-button round block color="#000000" @click.stop="onConfirm">
          <span text="base">{{ t('key.business.select.ticket.apply') }}</span>
        </van-button>
      </div>
    </div>
  </van-popup>
</template>

<style lang="scss" scoped>
.az-search__popup {
  height: 100%;
  :deep(.van-popup__close-icon) {
    top: 11px;
  }
}
</style>
