<script lang="ts" setup>
import { E_RANK_TYPE } from '~/enums'

defineProps<{
  pageType: E_RANK_TYPE
  isDetail?: boolean
}>()
const { t } = useI18n()
const showPopup = defineModel({ default: false })
const filterRef = useTemplateRef('filter-ref')

function onConfirm() {
  showPopup.value = false
  filterRef.value?.onConfirm()
}
</script>

<template>
  <ElDialog
    v-model="showPopup"
    :title="t('key.experience.add.filters')"
    width="700"
    append-to-body
  >
    <BusinessBFilter v-if="pageType === E_RANK_TYPE.ATTRACTIONS && showPopup" ref="filter-ref" :is-detail="isDetail" :columns="2" />
    <BusinessBExerienceFilter v-if="pageType === E_RANK_TYPE.EXPERIENCE" ref="filter-ref" />
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="showPopup = false">
          {{ t('key.common.button.cancel') }}
        </el-button>
        <el-button type="primary" @click="onConfirm">
          {{ t('key.common.button.confirm') }}
        </el-button>
      </div>
    </template>
  </ElDialog>
</template>

<style lang="scss" scoped>
.az-search__popup {
  height: 100%;
  :deep(.van-popup__close-icon) {
    top: 11px;
  }
}
</style>
